.App {
  text-align: center;
}

html {
  background: radial-gradient(#3b213d 25%, #000000 30%);
  background-size: 40px 40px;
  -webkit-animation: bscale 100s infinite linear;
  -webkit-animation-animation: bscale 100s infinite linear;
}

@keyframes bscale {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 100% 100%;
  }
}
.gashapon-main {
  text-align: center;
}

svg#ham-btn {
  margin: 2rem;
  border: 1px solid black;
  fill: #383838;
  /* percentage of viewport - height will autocalculate */
  width: 30vw;
  height: auto;
  text-align: center;
}


.bounceAnimation{
  animation-name: grow; 
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  animation-iteration-count: infinite;

}




@keyframes bounce {
  from { 
    transform: translateY(0) scale(1);
  }
  to   { 
    transform: translateY(var(--drop-height)) scale(1, 0.7);
  }
}

@keyframes grow {
  from {
    transform: scale(0.2, 0.5);
  }

  to {
    transform: scale(1, 0.1);   
  }
}